import { DefaultRoute } from "../router/routes"

// --------------------------------------------------------------------------------
// Yutt
/*
gerate uuid: #> uuidgen
Usage:
import {encryptDataSalt, decryptDataSalt} from '../utility/Utils'
import {encryptData, decryptData} from '../utility/Utils'
const salt = process.env.SALT_CRYPT || 'C9971E7D-181C-4763-A3A1-F1A20B3C297A'
const originalData = {
  name: 'IDKBlogs'
}
const encryptedOutput = encryptData(originalData, salt)
localStorage.setItem("encryptedOutput", encryptedOutput)
const decryptedOutput = localStorage.getItem("encryptedOutput")
*/

import CryptoJS from 'crypto-js'
import {Coffee, X} from "react-feather"
import Avatar from "../@core/components/avatar"
import toast from "react-hot-toast"
import axios from "axios"

// function generateUUID() { // Public Domain/MIT
/*
Usage:
import { generateUUID } from '@src/utility/Utils'
console.log(generateUUID())
*/
export const generateUUID = () => { // Public Domain/MIT
  let d = new Date().getTime() //Timestamp
  let d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now() * 1000)) || 0 //Time in microseconds since page-load or 0 if unsupported
  return 'xxxxxxxx-xxxx-xxxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      let r = Math.random() * 16//random number between 0 and 16
      if (d > 0) { //Use timestamp until depleted
          r = (d + r) % 16 | 0
          d = Math.floor(d / 16)
      } else { //Use microseconds since page-load if supported
          r = (d2 + r) % 16 | 0
          d2 = Math.floor(d2 / 16)
      }
      return ((c === 'x') ? r : ((r & 0x3) | 0x8)).toString(16)
  })
}

export const encryptDataSalt = (data, salt) => {
  return CryptoJS.AES.encrypt(JSON.stringify(data), salt).toString()
}

export const decryptDataSalt = (ciphertext, salt) => {
  const bytes = CryptoJS.AES.decrypt(ciphertext, salt)
  try {
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
  } catch (err) {
    return null
  }
}

export const encryptData = (data) => {
  const salt = process.env.SALT_CRYPT || 'C9971E7D-181C-4763-A3A1-F1A20B3C297A'
  return CryptoJS.AES.encrypt(JSON.stringify(data), salt).toString()
}

export const decryptData = (ciphertext) => {

  if (ciphertext === null) return null

  const salt = process.env.SALT_CRYPT || 'C9971E7D-181C-4763-A3A1-F1A20B3C297A'
  const bytes = CryptoJS.AES.decrypt(ciphertext, salt)

  try {
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
  } catch (err) {
    return null
  }
}

// dec2hex :: Integer -> String
// i.e. 0-255 -> '00'-'ff'
export const dec2hex = (dec) => { // Public Domain/MIT
  return dec.toString(16).padStart(2, "0")
}

// import { generateString } from "@src/utility/Utils"
// generateId :: Integer -> String
// Please note, Don't have to support old browsers
export const generateString = (len) => {
  const arr = new Uint8Array((len || 40) / 2)
  window.crypto.getRandomValues(arr)
  return Array.from(arr, dec2hex).join('')
}

/* Usage:
import {ToastMessage} from "../utility/Utils"
import { ToastMessage } from "@src/utility/Utils"
import toast from "react-hot-toast"
toast(t => (
  <ToastMessage t={t} type='info' title='' detail='' />
))
type: primary, secondary, danger, success, warning, info, dark, transparent
*/
export const ToastMessage = ({ t, type, title, detail }) => {
  return (
    <div className='d-flex'>
      <div className='me-1'>
        <Avatar size='sm' color={type} icon={<Coffee size={12} />} />
      </div>
      <div className='d-flex flex-column'>
        <div className='d-flex justify-content-between'>
          <h6>{ title }</h6>
          <X size={12} className='cursor-pointer' onClick={() => toast.dismiss(t.id)} />
        </div>
        <span>{ detail }</span>
      </div>
    </div>
  )
}

/*
Usage:
const defaultValues = {
  user_id: '',
  action: '',
  value_old: '',
  value_new: ''
}
addTransLog()
*/
export const addTransLog = (...args) => {
  const res = axios.get('https://geolocation-db.com/json/')

  const defaultValues = {
    ref_id: generateUUID(),
    ref_ipaddress: res.data.IPv4,
    ref_country_code: res.data.country_code,
    created_at: Date.now(),
    created_by: '',
    updated_at: Date.now(),
    updated_by: ''
  }
  args.push(...defaultValues)
  axios.post('/translog', {...args, ...defaultValues})
  return null
}
// --------------------------------------------------------------------------------

// ** Checks if an object is empty (returns boolean)
export const isObjEmpty = (obj) => Object.keys(obj).length === 0

// ** Returns K format from a number
export const kFormatter = (num) => (num > 999 ? `${(num / 1000).toFixed(1)}k` : num)

// ** Converts HTML to string
export const htmlToString = (html) => html.replace(/<\/?[^>]+(>|$)/g, "")

// ** Checks if the passed date is today
const isToday = (date) => {
  const today = new Date()
  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  )
}

/**
 ** Format and return date in Humanize format
 ** Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 ** Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (
  value,
  formatting = { month: "short", day: "numeric", year: "numeric" }
) => {
  if (!value) return value
  return new Intl.DateTimeFormat("en-US", formatting).format(new Date(value))
}

// ** Returns short month of passed date
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
  const date = new Date(value)
  let formatting = { month: "short", day: "numeric" }

  if (toTimeForCurrentDay && isToday(date)) {
    formatting = { hour: "numeric", minute: "numeric" }
  }

  return new Intl.DateTimeFormat("en-US", formatting).format(new Date(value))
}

/**
 ** Return if user is logged in
 ** This is completely up to you and how you want to store the token in your frontend application
 *  ? e.g. If you are using cookies to store the application please update this function
 */
export const isUserLoggedIn = () => localStorage.getItem("userData")
// export const getUserData = () => JSON.parse(localStorage.getItem("userData"))
export const getUserData = () => JSON.parse(decryptData(localStorage.getItem("userData")))

/**
 ** This function is used for demo purpose route navigation
 ** In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 ** Please note role field is just for showing purpose it's not used by anything in frontend
 ** We are checking role just for ease
 * ? NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = (userRole) => {
  if (userRole === "admin") return DefaultRoute
  if (userRole === "client") return "/access-control"
  // return "/login"
  // Yutt
  return "/signin"
}

// ** React Select Theme Colors
export const selectThemeColors = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: "#7367f01a", // for option hover bg-color
    primary: "#7367f0", // for selected option bg-color
    neutral10: "#7367f0", // for tags bg-color
    neutral20: "#ededed", // for input border-color
    neutral30: "#ededed" // for input hover border-color
  }
})

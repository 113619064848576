// ** React Imports
import { Navigate } from "react-router-dom"
// import { useContext, Suspense } from "react"
import { Suspense } from "react"

// ** Context Imports
// import { AbilityContext } from "@src/utility/context/Can"

// Yutt
import {decryptData, ToastMessage} from '../../../utility/Utils'
import toast from "react-hot-toast"

const PrivateRoute = ({ children, route }) => {
  // ** Hooks & Vars
  // const ability = useContext(AbilityContext)
  // const user = JSON.parse(localStorage.getItem("userData"))
  const user = JSON.parse(decryptData(localStorage.getItem("userData")))

  if (route) {
    // let action = null
    // let resource = null
    let restrictedRoute = false

    if (route.meta) {
      // action = route.meta.action
      // resource = route.meta.resource
      restrictedRoute = route.meta.restricted
    }
    if (!user) {
      toast(t => (
        <ToastMessage t={t} type='danger' title='Authentication' detail='Authentication required.' />
      ))
      return <Navigate to="/signin" />
    }
    if (user && restrictedRoute) {
      return <Navigate to="/" />
    }
    if (user && restrictedRoute && user.role === "client") {
      return <Navigate to="/access-control" />
    }
    // if (user && !ability.can(action || "read", resource)) {
    //   return <Navigate to="/misc/not-authorized" replace />
    // }
  }

  return <Suspense fallback={null}>{children}</Suspense>
}

export default PrivateRoute

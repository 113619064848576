// ** React Imports
import { Fragment } from "react"
// import { useNavigate } from 'react-router-dom'
// import { useTranslation } from 'react-i18next'

// ** Custom Components
import NavbarUser from "./NavbarUser"
import NavbarBookmarks from './NavbarBookmarks'

// ** Third Party Components
import { Sun, Moon, Menu } from "react-feather"

// ** Reactstrap Imports
import { Button, NavItem, NavLink } from "reactstrap"

const ThemeNavbar = (props) => {
  // ** Props
  const { skin, setSkin, setMenuVisibility } = props // setMenuVisibility

  // ** Function to toggle Theme (Light/Dark)
  const ThemeToggler = () => {
    if (skin === "dark") {
      return <Sun className="ficon" onClick={() => setSkin("light")} />
    } else {
      return <Moon className="ficon" onClick={() => setSkin("dark")} />
    }
  }

  // ** Hooks
  // const { t } = useTranslation()

  // const navigate = useNavigate()
  // const navigateHome = () => {
  //   navigate('/contactus')
  // }

  return (
    <Fragment>
      <div className="bookmark-wrapper d-flex align-items-center">

        {/* <Button.Ripple className='round' color='primary' outline onClick={() => navigateHome() }>  */} {/* e.preventDefault() */}
          {/* { t('Create Listing Free') }  */}
        {/* </Button.Ripple> */}

        <NavbarBookmarks setMenuVisibility={setMenuVisibility} />
      </div>
      <NavbarUser skin={skin} setSkin={setSkin} />
    </Fragment>
  )
}

export default ThemeNavbar

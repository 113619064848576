// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'

// ** UseJWT import to get config
import useJwt from '@src/auth/jwt/useJwt'

// ** Third Party Components
import toast from 'react-hot-toast'

// import * as Icons from 'react-feather'
import { X } from 'react-feather'

// ** Custom Components
import Avatar from '@components/avatar'

// Yutt
import {encryptData, decryptData, ToastMessage} from '../utility/Utils'

const messageSignout = () => {
  toast(t => (
    <ToastMessage t={t} type='info' title='Status' detail='Signout successfully.' />
  ))
  // toast(t => (
  //   <div className='d-flex'>
  //     <div className='me-1'>
  //       <Avatar size='sm' color='success' />
  //     </div>
  //     <div className='d-flex flex-column'>
  //       <div className='d-flex justify-content-between'>
  //         <h6 className='toast-title'>Information</h6>
  //         <X size={12} className='cursor-pointer' onClick={() => toast.dismiss(t.id)} />
  //       </div>
  //       <span role='img' aria-label='toast-text'>
  //         Signout successfully.
  //       </span>
  //     </div>
  //   </div>
  // ))
}

const config = useJwt.jwtConfig

const initialUser = () => {
  // const item = window.localStorage.getItem('userData')
  const item = decryptData(window.localStorage.getItem('userData'))

  //** Parse stored json or if none return initialValue
  return item ? JSON.parse(item) : {}
}

export const authSlice = createSlice({
  name: 'authentication',
  initialState: {
    userData: initialUser()
  },
  reducers: {
    handleLogin: (state, action) => {
      state.userData = action.payload
      state[config.storageTokenKeyName] = action.payload[config.storageTokenKeyName]
      state[config.storageRefreshTokenKeyName] = action.payload[config.storageRefreshTokenKeyName]

      // Remark to use encryption
      // localStorage.setItem('userData', JSON.stringify(action.payload))
      // localStorage.setItem(config.storageTokenKeyName, JSON.stringify(action.payload.accessToken))
      // localStorage.setItem(config.storageRefreshTokenKeyName, JSON.stringify(action.payload.refreshToken))

      localStorage.setItem('userData', encryptData(JSON.stringify(action.payload)))
      localStorage.setItem(config.storageTokenKeyName, encryptData(JSON.stringify(action.payload.accessToken)))
      localStorage.setItem(config.storageRefreshTokenKeyName, encryptData(JSON.stringify(action.payload.refreshToken)))
    },

    handleLogout: state => {
      state.userData = {}
      state[config.storageTokenKeyName] = null
      state[config.storageRefreshTokenKeyName] = null
      // ** Remove user, accessToken & refreshToken from localStorage
      localStorage.removeItem('userData')
      localStorage.removeItem(config.storageTokenKeyName)
      localStorage.removeItem(config.storageRefreshTokenKeyName)
      messageSignout()
    }
  }
})

export const { handleLogin, handleLogout } = authSlice.actions

export default authSlice.reducer

// ** Icons Import
import { Heart } from "react-feather"

const Footer = () => {
  const current_year = new Date().getFullYear()
  const display_year = (current_year === 2022) ? current_year : `2022-${current_year}`
  return (
    <p className="clearfix mb-0">
      <span className="float-md-start d-block d-md-inline-block mt-25">
        Copyright © {display_year}{"  "}
        <a
          href="https://u-rod.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          u-rod.com
        </a>
        <span className="d-none d-sm-inline-block">, All rights Reserved</span>
      </span>
      <span className="float-md-end d-none d-md-block">
        Hand-crafted & Made with
        <Heart size={14} />
      </span>
    </p>
  )
}

export default Footer

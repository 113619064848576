// ** React Imports
import { Link } from "react-router-dom"
import { useEffect, useState } from 'react'

// ** Custom Components
import Avatar from "@components/avatar"

// ** Utils
import { isUserLoggedIn } from '@utils'

// ** Store & Actions
import { useDispatch } from 'react-redux'
import { handleLogout } from '@store/authentication'

// ** Third Party Components
import { useTranslation } from 'react-i18next'
import {
  User,
  Mail,
  CheckSquare,
  MessageSquare,
  Settings,
  CreditCard,
  HelpCircle,
  Power
} from "react-feather"

// ** Reactstrap Imports
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem
} from "reactstrap"

// ** Default Avatar Image
import defaultAvatar from "@src/assets/images/portrait/small/avatar-s-11.jpg"

// Yutt
// import {decryptData} from '../../../../utility/Utils'
import {decryptData} from '@src/utility/Utils'

const UserDropdown = () => {
  // ** Hooks
  const { t } = useTranslation()

  // ** Store Vars
  const dispatch = useDispatch()

  // ** State
  const [userData, setUserData] = useState(null)

  //** ComponentDidMount
  useEffect(() => {
    if (isUserLoggedIn() !== null) {
      // setUserData(JSON.parse(localStorage.getItem('userData')))
      setUserData(JSON.parse(decryptData(localStorage.getItem('userData'))))
    }
  }, [])

  //** Vars
  const userAvatar = (userData && userData.avatar) || defaultAvatar

  const accountTitle = (userData && userData.username) || ''

  return (
    <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
      <DropdownToggle
        href="/"
        tag="a"
        className="nav-link dropdown-user-link"
        onClick={(e) => e.preventDefault()}
      >
        <div className="user-nav d-sm-flex d-none">
          <span className="user-name fw-bold">{ accountTitle }</span> { /* John Doe */ }
          <span className="user-status">Admin</span>
        </div>
        <Avatar
          img={userAvatar}
          imgHeight="40"
          imgWidth="40"
          status="online"
        />
      </DropdownToggle>
      <DropdownMenu end>
        <DropdownItem tag={Link} to="/account/profile" >  { /* onClick={(e) => e.preventDefault()}> */ }
          <User size={14} className="me-75" />
          <span className="align-middle">Profile</span>
        </DropdownItem>
        <DropdownItem tag={Link} to="/account/settings" >  { /* onClick={(e) => e.preventDefault()}> */ }
          <User size={14} className="me-75" />
          <span className="align-middle">Settings</span>
        </DropdownItem>
        <DropdownItem tag={Link} to="/" onClick={(e) => e.preventDefault()}>
          <Mail size={14} className="me-75" />
          <span className="align-middle">Inbox</span>
        </DropdownItem>
        <DropdownItem tag={Link} to="/" onClick={(e) => e.preventDefault()}>
          <CheckSquare size={14} className="me-75" />
          <span className="align-middle">Tasks</span>
        </DropdownItem>
        <DropdownItem tag={Link} to="/" onClick={(e) => e.preventDefault()}>
          <MessageSquare size={14} className="me-75" />
          <span className="align-middle">Chats</span>
        </DropdownItem>

        <DropdownItem divider />

        <DropdownItem tag={Link} to="/pricing"> {/* onClick={(e) => e.preventDefault()}> */}
          <CreditCard size={14} className="me-75" />
          <span className="align-middle">{ t('Pricing') }</span>
        </DropdownItem>
        <DropdownItem tag={Link} to="/faq"> {/* onClick={(e) => e.preventDefault()}> */}
          <HelpCircle size={14} className="me-75" />
          <span className="align-middle">{ t('FAQ') }</span>
        </DropdownItem>

        <DropdownItem divider />

        <DropdownItem tag={Link} to="/" onClick={() => dispatch(handleLogout())}>
          <Power size={14} className="me-75" />
          <span className="align-middle">{ t('Sign Out') }</span>
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  )
}

export default UserDropdown

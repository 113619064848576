import { Mail, Home, Search, Tag } from "react-feather"

export default [
  {
    id: "home",
    title: "Home",
    icon: <Home size={20} />,
    navLink: "/home"
  },
  {
    id: "search",
    title: "Search",
    icon: <Search size={20} />,
    navLink: "/#"
  },
  {
    id: "adsvertisement",
    title: "Create Listing",
    icon: <Tag size={20} />,
    navLink: "/second-page"
  },
  {
    id: "aboutus",
    title: "About Us",
    icon: <Mail size={20} />,
    navLink: "/aboutus"
  },
  {
    id: "contactus",
    title: "Contact Us",
    icon: <Mail size={20} />,
    navLink: "/contactus"
  }
]

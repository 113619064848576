import { Mail, Home, List, Circle, Settings, User, FileText } from "react-feather"

export default [
  {
    id: "home",
    title: "Home",
    icon: <Home size={20} />,
    navLink: "/home"
  },
  {
    id: "secondPage",
    title: "Second Page",
    icon: <Mail size={20} />,
    navLink: "/second-page"
  },
  {
    id: 'listing',
    title: 'My Listing',
    icon: <List size={20} />,
    badge: 'light-warning',
    badgeText: '2',
    children: [
      {
        id: 'analyticsDash',
        title: 'Pending approval',
        icon: <Circle size={12} />,
        navLink: '/#'
      },
      {
        id: 'listings_archived',
        title: 'Archived listings',
        icon: <Circle size={12} />,
        navLink: '/#'
      },
      {
        id: 'listings_favourite',
        title: 'Favourite listings',
        icon: <Circle size={12} />,
        navLink: '/#'
      }
    ]
  },
  {
    id: "messenger",
    title: "Messenger",
    icon: <Mail size={20} />,
    navLink: "/#"
  },
  {
    id: "transactions",
    title: "Transactions",
    icon: <FileText size={20} />,
    navLink: "/#"
  },
  {
    id: "my_account",
    title: "My Account",
    icon: <User size={20} />,
    navLink: "/#"
  },
  {
    id: "my_settings",
    title: "My Settings",
    icon: <Settings size={20} />,
    navLink: "/#"
  },
  {
    id: "line_auth",
    title: "LINE Auth",
    icon: <Settings size={20} />,
    navLink: "/line-signin"
  },
  {
    id: "test_leaflet",
    title: "Leaflet Map",
    icon: <Settings size={20} />,
    navLink: "/test-leaflet"
  },
  {
    id: "test_longdo",
    title: "Longdo Map",
    icon: <Settings size={20} />,
    navLink: "/test-longdo"
  }
]

// ** React Imports
import { Link } from 'react-router-dom'
import { useEffect, useState } from 'react'

// ** Custom Components
import Avatar from '@components/avatar'

// ** Utils
import { isUserLoggedIn } from '@utils'

// ** Store & Actions
// import { useDispatch } from 'react-redux'
// import { handleLogout } from '@store/authentication'

// ** Third Party Components
import { User, UserPlus, Mail, CheckSquare, MessageSquare, Settings, CreditCard, HelpCircle, Power } from 'react-feather'
import { useTranslation } from 'react-i18next'

// ** Reactstrap Imports
import { UncontrolledDropdown, DropdownMenu, DropdownToggle, DropdownItem } from 'reactstrap'

// ** Default Avatar Image
// import defaultAvatar from '@src/assets/images/portrait/small/avatar-s-11.jpg'
// import defaultAvatar from '@src/assets/images/_custom/avatars/avatar-01.png'
// import defaultAvatar from '@src/assets/images/_custom/avatars/avatar-02.png'

const UserDropdown = () => {
  // ** Hooks
  const { t } = useTranslation()

  // ** Store Vars
  // const dispatch = useDispatch()

  // ** State
  // const [userData, setUserData] = useState(null)
  const [setUserData] = useState(null)

  //** ComponentDidMount
  useEffect(() => {
    if (isUserLoggedIn() !== null) {
      setUserData(JSON.parse(localStorage.getItem('userData')))
    }
  }, [])

  //** Vars
  // const userAvatar = (userData && userData.avatar) || defaultAvatar

  return (
    <UncontrolledDropdown tag='li' className='dropdown-user nav-item'>
      <DropdownToggle href='/' tag='a' className='nav-link dropdown-user-link' onClick={e => e.preventDefault()}>
        {/* <Avatar img={userAvatar} imgHeight='30' imgWidth='30' status='' /> */}
        <User size={35} className='nav-link dropdown-user-link' />
      </DropdownToggle>
      <DropdownMenu end>
        <DropdownItem tag={Link} to='/signin'>
          <User size={14} className='me-75' />
          <span className='align-middle'>{ t('Sign In') }</span>
        </DropdownItem>
        <DropdownItem tag={Link} to='/signup'>
          <UserPlus size={14} className='me-75' />
          <span className='align-middle'>{ t('Create an account') }</span>
        </DropdownItem>
        <DropdownItem tag={Link} to='/forgot-password'>
          <CheckSquare size={14} className='me-75' />
          <span className='align-middle'>{ t('Forgot Password') }</span>
        </DropdownItem>
        <DropdownItem tag={Link} to='/resend-activation'>
          <MessageSquare size={14} className='me-75' />
          <span className='align-middle'>{ t('Resend Activation') }</span>
        </DropdownItem>
        <DropdownItem divider />
        <DropdownItem tag={Link} to='/pricing'>
          <MessageSquare size={14} className='me-75' />
          <span className='align-middle'>{ t('Pricing') }</span>
        </DropdownItem>
        <DropdownItem tag={Link} to='/faq'>
          <MessageSquare size={14} className='me-75' />
          <span className='align-middle'>{ t('FAQ') }</span>
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  )
}

export default UserDropdown

// ** React Imports
import { lazy } from 'react'
// import { Navigate } from 'react-router-dom'

const Signin = lazy(() => import('../../views/apps/auth/Signin'))
const Signup = lazy(() => import('../../views/apps/auth/Signup'))
const ForgotPassword = lazy(() => import('../../views/apps/auth/ForgotPassword'))
const Profile = lazy(() => import('../../views/apps/account/profile'))
const AccountSettings = lazy(() => import('../../views/apps/account/settings'))
const ResetPassword = lazy(() => import('../../views/apps/auth/ResetPassword'))
const ResendActivation = lazy(() => import('../../views/apps/auth/ResendActivation'))
const VerifyEmail = lazy(() => import('../../views/apps/auth/VerifyEmail'))
const Home = lazy(() => import('@src/views/apps/site/home'))
// const Home = lazy(() => import('../../views/apps/home'))

// const EcommerceShop = lazy(() => import('../../views/apps/ecommerce/shop'))
// const EcommerceDetail = lazy(() => import('../../views/apps/ecommerce/detail'))
// const EcommerceWishlist = lazy(() => import('../../views/apps/ecommerce/wishlist'))
// const EcommerceCheckout = lazy(() => import('../../views/apps/ecommerce/checkout'))

const CustomRoutes = [
  {
    element: <Signin />,
    path: '/signin',
    meta: {
      layout: 'blank',
      publicRoute: true,
      restricted: false
    }
  },
  {
    element: <Signup />,
    path: '/signup',
    meta: {
      layout: 'blank',
      publicRoute: true
    }
  }, {
    element: <ForgotPassword />,
    path: '/forgot-password',
    meta: {
      layout: 'blank',
      publicRoute: true
    }
  }, {
    element: <ResendActivation />,
    path: '/resend-activation',
    meta: {
      layout: 'blank',
      publicRoute: true
    }
  }, {
    element: <ResetPassword />,
    path: '/reset-password',
    meta: {
      layout: 'blank',
      publicRoute: true
    }
  }, {
    element: <VerifyEmail />,
    path: '/verify-email',
    meta: {
      layout: 'blank',
      publicRoute: true
    }
  }, {
    path: '/account/profile',
    element: <Profile />,
    meta: {
      privateRoute: true
      // restricted: true
    }
  }, {
    path: '/account/settings',
    element: <AccountSettings />,
    meta: {
      privateRoute: true
      // restricted: true
    }
  }, {
  }, {
    element: <Home />,
    // path: '/apps/ecommerce/shop',
    path: '/home',
    meta: {
      className: 'ecommerce-application',
      // layout: 'horizontal',
      publicRoute: true,
      restricted: false
    }
  }
  // ,{
  //   element: <Home />,
  //   path: '/home',
  //   meta: {
  //     className: 'ecommerce-application',
  //     layout: 'horizontal',
  //     publicRoute: true,
  //     restricted: false
  //   }
  // }
]

export default CustomRoutes

// ** React Imports
import { lazy } from 'react'

const Home = lazy(() => import('@src/views/apps/site/home'))
const AboutUs = lazy(() => import('@src/views/apps/site/aboutus'))
const ContactUs = lazy(() => import('@src/views/apps/site/contactus'))
const Faq = lazy(() => import('@src/views/apps/site/faq'))
const Pricing = lazy(() => import('@src/views/apps/site/pricing'))
const LINESignin = lazy(() => import('@src/views/apps/auth/LINESignin'))
const Leaflet = lazy(() => import('@src/views/apps/test/leaflet'))
const LongdoMap = lazy(() => import('@src/views/apps/test/longdo-map'))

const CustomSiteRoutes = [
  {
    element: <Home />,
    path: '/home',
    meta: {
      publicRoute: true,
      restricted: false
    }
  },
  {
    element: <AboutUs />,
    path: '/aboutus',
    meta: {
      publicRoute: true,
      restricted: false
    }
  },
  {
    element: <ContactUs />,
    path: '/contactus',
    meta: {
      publicRoute: true,
      restricted: false
    }
  },
  {
    element: <Faq />,
    path: '/faq',
    meta: {
      publicRoute: true,
      restricted: false
    }
  },
  {
    element: <Pricing />,
    path: '/pricing',
    meta: {
      publicRoute: true,
      restricted: false
    }
  },
  {
    element: <LINESignin />,
    path: '/line-signin',
    meta: {
      publicRoute: true,
      restricted: false
    }
  },
  {
    element: <Leaflet />,
    path: '/test-leaflet',
    meta: {
      publicRoute: true,
      restricted: false
    }
  },
  {
    element: <LongdoMap />,
    path: '/test-longdo',
    meta: {
      publicRoute: true,
      restricted: false
    }
  }
]

export default CustomSiteRoutes
